<template>
  <div
    v-loading="loading"
    :class="['order-block', {'active': basket.id === activeBasketId}]"
  >
    <div
      class="order-block__header"
      @click="toggle"
    >
      <div class="title-wrap">
        <el-checkbox
          :value="multipleSelection.includes(basket.id)"
          class="v-step-9"
          @change="change"
        />
        <h2>{{ basket.name }}</h2>
      </div>
      <div
        v-if="!visibleInfo"
        :class="b('price', { footerPrice: true })"
      >
        <price-output
          v-loading="loadSum"
          :style="filteredBasketItems.length ? 'color: #fb7e7e; font-size: .8em; text-decoration: line-through' : ''"
          :price="normalizePrice(getPrice(basket, 'basketSum', 'tengeBasketSum'))"
        />
        <template v-if="filteredBasketItems.length ">
          <price-output
            v-loading="loadSum"
            :price="normalizePrice(getPrice(basket, 'basketSum', 'tengeBasketSum') - filteredBasketItems.reduce((acc, item) => (acc + item.price * item.quantity), 0))"
          />
        </template>
      </div>
      <button
        :class="b('view-more-button', { active: visibleInfo })"
        class="v-step-11"
      >
        <i class="el-icon-caret-right" />
      </button>
    </div>
    <div
      v-if="visibleInfo"
      class="order-block__body"
    >
      <OrderCreateBasketItems
        class="order-block__product-table"
        :products-without-active="productsWithoutActive"
        :product-with-error="productWithError"
        :products-with-errors="productsWithErrors"
        :basket-id="basket.id"
        :basket-items="sortedBasketItems"
        :products-over-stock="productsOverStock"
        @deleteProduct="deleteProduct"
        @changeQuantity="changeQuantity"
      />
      <div class="order-block__footer">
        <div
          v-if="visibleInfo"
          :class="b('price', { footerPrice: true })"
        >
          <price-output
            v-loading="loadSum"
            :style="filteredBasketItems.length ? 'color: #fb7e7e; font-size: .8em; text-decoration: line-through' : ''"
            :price="normalizePrice(sortedBasketItems.reduce((acc, item) => (acc + item.price * item.quantity), 0))"
          />
          <template v-if="filteredBasketItems.length ">
            <price-output
              v-loading="loadSum"
              :price="normalizePrice(getPrice(basket, 'basketSum', 'tengeBasketSum') - filteredBasketItems.reduce((acc, item) => (acc + item.price * item.quantity), 0))"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'utils/debounce';
import {
  mapActions, mapGetters, mapMutations,
  mapState
} from 'vuex';

import { getPrice, normalizePrice } from '@/utils/normalizePrice';

import OrderCreateBasketItems from './orderCreateBasketItems';

export default {
  name: 'OrderCreateBasketBlock',
  block: 'order-block',
  components: { OrderCreateBasketItems },
  model: {
    prop: 'multipleSelection',
    event: 'select',
  },
  props: {
    /**
     * @type IBasket
     */
    basket: {
      type: Object,
      required: true
    },
    productsWithoutActive: {
      type: Array,
      required: true
    },
    productsOverStock: {
      type: Array,
      required: true
    },
    productWithError: {
      type: Number,
      required: false,
      default: null
    },
    productsWithErrors: {
      type: Array,
      required: false,
      default: null
    },
    multipleSelection: {
      type: Array,
      required: true
    },
    isOpen: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  data() {
    return {
      loadSum: false,
      visibleInfo: this.isOpen,
      loading: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['_roleAdmin']),
    ...mapState('ui', ['windowSize']),
    ...mapState('basket', ['activeBasketId']),
    sortedBasketItems() {
      const { basket, productsWithoutActive, productsOverStock } = this;
      return basket.basketItems
        .sort((a, b) => Number(a.basketItemId) - Number(b.basketItemId))
        .sort(item => (productsWithoutActive.includes(item.productId) ? -1 : 0))
        .sort(item => (productsOverStock.includes(item.productId) ? -1 : 0));
    },
    filteredBasketItems() {
      const { basket, productsWithoutActive } = this;
      return basket.basketItems.filter(item => !!productsWithoutActive.includes(item.productId));
    }
  },
  methods: {
    ...mapActions('basket', ['DeleteProductInMultiBasket', 'PutChangeQuantityProduct']),
    ...mapMutations('basket', ['SET_ACTIVE_BASKET']),
    toggle() {
      if (!this.visibleInfo) {
        this.SET_ACTIVE_BASKET(this.basket.id);
      }
      this.visibleInfo = !this.visibleInfo;
    },
    async deleteProduct(basketItemId) {
      this.$emit('delete', this.basket.id);
      await this.DeleteProductInMultiBasket({
        basketId: this.basket.id,
        basketItemId
      }).then(() => {
        this.$message.success('Товар удалён из корзины');
      }).catch((err) => {
        this.$alert(err.message || err, { type: 'error', title: 'Не удалось удалить товар из корзины' });
      });
    },
    changeQuantity: debounce(async function changeQuantityFunc(row) {
      this.loadSum = true;
      const { basketItemId, quantity, supplier } = row;
      try {
        await this.PutChangeQuantityProduct({
          basketItemId,
          quantity: !quantity ? 1 : quantity,
          supplier: JSON.parse(supplier),
          updateBasket: false
        })
        this.updateSum();

        this.$emit('changeQuantity', row);
      } catch (message) {
        this.$alert(message, 'Недостаточно товара', {
          confirmButtonText: 'OK',
        });
      } finally {
        this.loadSum = false;
      }
    }),
    updateSum() { this.$emit('updateSum'); },
    change(val) {
      this.$emit('select', val
        ? [...this.multipleSelection, this.basket.id]
        : this.multipleSelection.filter(item => item !== this.basket.id));
      this.updateSum();
    },
    normalizePrice,
    getPrice,
  }
};
</script>

<style scoped lang="sass">
  h3
    font-size: 12px
    color: #636363
    font-weight: 400
    margin: 0
  p
    font-size: 12px
    color: #282828
    margin: 2px 0 15px 0
  .order-block
    position: relative
    padding: 10px 20px
    background: white
    border: 1px solid #ebeef5
    box-shadow: 0 9px 22px 0 rgba(0, 0, 50, .05)
    border-radius: 8px
    margin-bottom: 10px
    &.active
      border-color: #53a3d3
    &__header
      display: flex
      flex-flow: nowrap
      align-items: center
      padding-right: 30px
      cursor: pointer
      > *
        position: relative
        z-index: 2
      &::before
        content: ''
        position: absolute
        top: 0
        left: 0
        right: 0
        bottom: 0
        background: radial-gradient(circle at top right, #ecf5ff, white 30%)
        opacity: 0
        z-index: 1
        border-radius: 8px
        transition: .2s
      &:hover::before
        opacity: 1
      @media (max-width: 700px)
        flex-flow: column
        align-items: flex-start
        padding-right: 0
      .title-wrap
        display: flex
        flex-flow: nowrap
        align-items: center
        margin-right: 30px
        min-width: 200px
        @media (max-width: 700px)
          margin-right: 0
          margin-bottom: 5px
      h2
        margin-left: 10px
        font-size: 14px
        color: #636363
    &__view-more-button
      position: absolute
      right: 0
      height: 30px
      width: 50px
      padding: 0
      border: none
      background: none
      cursor: pointer
      border-radius: 0 8px 8px 0
      overflow: hidden
      i
        position: relative
        transform: rotate(0)
        transition: .2s
        z-index: 2
      &_active
        i
          transform: rotate(-90deg)
    &__data
      display: flex
      flex-flow: column
      font-size: 12px
      color: #282828
      margin-right: 15px
      opacity: .8
      @media (max-width: 700px)
        margin-right: 0
        margin-bottom: 5px
      span
        font-size: 10px
        color: #636363
    &__price
      font-size: 14px
      margin-left: auto
      color: #636363
      @media (max-width: 700px)
        margin-right: -30px
        &_footer-price
          margin-right: 0
          &:last-child
            margin-left: 10px
    &__buttons
      display: flex
      flex-flow: nowrap
      gap: 10px
    &__body
      position: relative
      padding-top: 10px
      padding-bottom: 10px
      z-index: 2
    &__footer
      display: flex
      flex-flow: nowrap
      align-items: center
      justify-content: flex-end
      margin-top: 10px
</style>
