<template>
  <div class="personal">
    <div class="personal__header">
      <div class="title">
        Пользователи
      </div>
    </div>

    <div>
      <div style="display: flex">
        <div style="padding: 5px;width: 250px;">
          <el-input
            v-model="name"
            size="small"
            placeholder="Поиск"
            @keydown.enter.native="searchBy"
          />
        </div>

        <div style="padding: 5px;align-self: flex-end">
          <el-select
            v-model="filterActiveValue"
            clearable
            placeholder="Активность"
            size="small"
            @change="setFilterActive"
          >
            <el-option
              v-for="item in filterActiveOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>

        <div style="padding: 5px;align-self: flex-end">
          <el-select
            v-model="filterChildValue"
            clearable
            placeholder="Фильтр"
            size="small"
            @change="setFilterChild"
          >
            <el-option
              v-for="item in filterChildOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>

        <div style="padding: 5px">
          <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="searchBy"
          />
        </div>
      </div>

      <el-table
        ref="multipleTable"
        class="product-table"
        :data="users"
        :height="height"
        :empty-text="emptyText"
        @row-click="getBasketItems"
      >
        <el-table-column
          type="expand"
          fade-transition
        >
          <template #default="userScope">
            <div style="padding-left: 49px">
              <el-table
                class="product-table"
                :data="userScope.row.users"
              >
                <el-table-column
                  label="id"
                  prop="id"
                  width="100"
                />
                <el-table-column
                  label="Имя"
                  prop="name"
                />
                <el-table-column
                  label="Статус"
                  prop="active"
                  width="120"
                  align="center"
                >
                  <template #default="scope">
                    <div
                      v-if="scope.row.active === true"
                      class="user-status_orange"
                    >
                      Активен
                    </div>
                    <div
                      v-else
                      class="user-status_gray"
                    >
                      Неактивен
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="Email"
                  prop="email"
                />

                <el-table-column
                  label="Account Id"
                  prop="accountId"
                  width="100"
                />

                <el-table-column
                  label="Роль"
                  prop="role"
                  width="75"
                />

                <el-table-column
                  label="А/Б сегмент"
                  prop="segment"
                  width="100"
                >
                  <template #default="scope">
                    <el-dropdown @command="(segment) => handleSegmentChange(scope.row, segment)">
                      <span class="el-dropdown-link">
                        {{ scope.row.segment || '—' }}<i class="el-icon-arrow-down el-icon--right" />
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="A">
                          A
                        </el-dropdown-item>
                        <el-dropdown-item command="B">
                          B
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </template>
                </el-table-column>

                <el-table-column
                  align="right"
                  width="75"
                >
                  <template #default="scope">
                    <el-button
                      style="z-index: 9999;"
                      size="mini"
                      type="primary"
                      @click.prevent="$router.push({
                        name: 'admin-history-backup',
                        params: {
                          id: scope.row.id
                        },
                        query: {
                          email: scope.row.email,
                          user: scope.row.name,
                          agent: userScope.row.name
                        }
                      })"
                    >
                      Лог
                    </el-button>
                  </template>
                </el-table-column>

                <el-table-column
                  align="right"
                  width="75"
                >
                  <template #default="scope">
                    <el-button
                      style="z-index: 9999;"
                      size="mini"
                      type="primary"
                      @click.prevent="handleLogin(scope.row)"
                    >
                      Войти
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="id"
          prop="id"
          width="100"
        />
        <el-table-column
          label="Имя"
          prop="name"
        />
        <el-table-column
          label="Статус"
          prop="active"
          width="120"
          align="center"
        >
          <template #default="scope">
            <div
              v-if="scope.row.active === true"
              class="user-status_orange"
            >
              Активен
            </div>
            <div
              v-else
              class="user-status_gray"
            >
              Неактивен
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="External Id"
          prop="externalId"
          width="90"
        />
        <el-table-column
          label="mos Id"
          prop="mosContactsId"
          width="80"
          align="center"
        />
        <el-table-column
          label="mtz Id"
          prop="mtzContactsId"
          width="80"
          align="center"
        />
        <el-table-column
          label="zros Id"
          prop="zrosContactsId"
          width="80"
          align="center"
        />
        <el-table-column
          label="Город"
          prop="warehouseId"
          width="120"
        >
          <template #default="scope">
            <div v-if="scope.row.warehouseId === 1">
              Омск
            </div>
            <div v-if="scope.row.warehouseId === 2">
              Новосибирск
            </div>
          </template>
        </el-table-column>

        <el-table-column
          label="Создан"
          prop="createdAt"
          width="220"
        >
          <template #default="scope">
            {{ scope.row.createdAt | date }}
          </template>
        </el-table-column>
        <el-table-column
          align="right"
          width="200"
        >
          <template #default="scope">
            <el-button
              style="z-index: 9999;margin: 0 10px"
              size="mini"
              @click.prevent="deleteCache(scope.row)"
            >
              Удалить кэш
            </el-button>
            <el-button
              style="z-index: 9999;"
              size="mini"
              type="primary"
              @click.prevent="handleLogin(scope.row)"
            >
              Войти
            </el-button>
          </template>
        </el-table-column>

        <template #append>
          <infinite-loading
            force-use-infinite-wrapper=".el-table__body-wrapper"
            @infinite="infiniteHandler"
          />
        </template>
      </el-table>
    </div>
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';

export default {
  name: 'ViewAdminUsers',
  components: { InfiniteLoading },
  filters: {
    date: (value) => {
      const date = new Date(value);

      return new Intl.DateTimeFormat('ru', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      }).format(date);
    },
  },
  metaInfo: {
    title: 'Пользователи'
  },
  data() {
    return {
      search: '',
      name: this.$route.query.name || '',
      filterChildValue: this.$route.query.child || '',
      filterActiveValue: this.$route.query.active || '',
      filterActiveOptions: [
        {
          value: 'active-true',
          label: 'Только активные'
        },
        {
          value: 'active-false',
          label: 'Только неактивные'
        },
      ],
      filterChildOptions: [
        {
          value: 'children-true',
          label: 'С доченими юзерами'
        },
        {
          value: 'children-false',
          label: 'Без дочерних юзеров'
        },
      ],
      haveUsers: null,
      active: null,
      disabled: false,
    };
  },
  computed: {
    height() {
      return 'calc(100vh - 150px)';
    },
    users() {
      return this.$store.state.admin.allAccounts;
    },
    emptyText() {
      return this.$store.state.admin.emptyText;
    },
  },
  watch: {
    name(val) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          name: val
        }
      });
    },
    filterChildValue(val) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          child: val
        }
      });
    },
    filterActiveValue(val) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          active: val
        }
      });
    }
  },
  mounted() {
    this.setFilterActive(this.$route.query.active);
    this.setFilterChild(this.$route.query.child);
    this.$store.dispatch('admin/GetAllAccounts', {
      name: this.name,
      active: this.active,
      haveUsers: this.haveUsers
    });
  },
  methods: {
    setFilterActive(value) {
      if (value === 'active-true') {
        this.active = true;
      }
      if (value === 'active-false') {
        this.active = false;
      }

      if (value === '') {
        this.active = null;
      }
    },
    setFilterChild(value) {
      if (value === 'children-true') {
        this.haveUsers = true;
      }
      if (value === 'children-false') {
        this.haveUsers = false;
      }
      if (value === '') {
        this.haveUsers = null;
      }
    },
    async handleLogin(row) {
      await this.$store.dispatch('admin/LoginAsUser', { userId: row.id })
        .then(({ token }) => {
          localStorage.setItem('user-token', token);
          localStorage.removeItem('user-email');
          localStorage.removeItem('user-refreshToken');
          this.$store.commit('auth/SET_TOKEN', token);
          window.location.pathname = 'dashboard/view-products';
        }).catch(err => this.$alert(err.message || err, 'Не удалось войти под пользователем'));
    },
    async deleteCache(row) {
      await this.$store.dispatch('admin/DeleteCache', { accountId: row.id })
        .then(() => {
          this.$message.success('Кэш удалён');
        }).catch(() => {
          this.$message.error('Ошибка удаления кэша');
        });
    },
    async getBasketItems(row) {
      await this.$store.dispatch('admin/GetUsersByAccount', { accountId: row.id })
        .then((data) => {
          row.users = data;
        });

      this.$refs.multipleTable.toggleRowExpansion(row);
    },
    async searchBy() {
      this.$store.commit('admin/SET_EMPTY_ACCOUNTS');
      await this.$store.dispatch('admin/GetAllAccounts', {
        name: this.name,
        active: this.active,
        haveUsers: this.haveUsers
      });
    },
    async infiniteHandler($state) {
      return;
      // TODO: refactor
      if (this.users.length < 10000 || this.users.length > 0) {
        try {
          await this.$store.dispatch('admin/GetAllAccounts', {
            name: this.name,
            active: this.active,
            haveUsers: this.haveUsers
          })
            .then((data) => {
              if (data.length === 0) {
                $state.complete();
              }
            });

          $state.loaded();
        } catch (e) {
          $state.complete();
        }
      } else {
        $state.reset();
      }
    },
    async handleSegmentChange(row, segment) {
      await this.$store.dispatch('admin/setUserSegment', {
        userId: row.id,
        segment
      }).then(() => {
        this.$message.success('А/Б сегмент обновлён');
        row.segment = segment;
      }).catch(() => {
        this.$message.error('Ошибка изменения сегмента');
      });
    }
  }
};
</script>

<style scoped lang="sass" src="../admin/access/admin.sass" />
