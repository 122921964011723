<template>
  <div>
    <h2>Авторизация</h2>
    <el-form
      ref="loginForm"
      hide-required-asterisk
      :model="model"
      status-icon
      :rules="rules"
      label-width="120px"
      label-position="top"
      class="demo-ruleForm"
      @submit.prevent.native="submit('loginForm')"
    >
      <el-form-item
        label="Email"
        prop="email"
        :error="error"
      >
        <el-input
          id="email"
          v-model="model.email"
          autocomplete="email"
          type="email"
          class="form-field"
        />
      </el-form-item>
      <el-form-item
        label="Пароль"
        prop="pass"
        :error="errorPass"
      >
        <el-input
          id="pass"
          ref="password"
          v-model="model.pass"
          class="form-field"
          minlength="6"
          show-word-limit
          type="password"
          autocomplete="off"
          show-password
        />
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          native-type="submit"
          :loading="sending"
        >
          Войти
        </el-button>
        <div class="forgot-link-position">
          <ForgotPass :start-email="model.email" />
        </div>
      </el-form-item>
    </el-form>
    <hr>
    <h3>Протестируйте наш сервис</h3>
    <h4>
      Для этого вы можете войти как гость. В режиме гостя <u>вы не сможете сделать заказ</u>,
      так же мы не гарантируем сохранность товаров в корзине
    </h4>
    <el-button
      size="small"
      :loading="guestSending"
      @click="authRequestGuest"
    >
      Войти как гость
    </el-button>
  </div>
</template>

<script>
import ForgotPass from '../../components/ForgotPass';

export default {
  name: 'AppLogin',
  metaInfo: {
    title: 'Войти в онлайн каталог ТФ «Николь»',
    titleTemplate: null
  },
  components: { ForgotPass },
  data() {
    return {
      error: '',
      errorPass: '',
      model: {
        pass: '',
        email: '',
      },
      rules: {
        pass: [
          {
            required: true, message: 'Пожалуйста введите пароль', trigger: 'blur'
          }
        ],
        email: [
          { required: true, message: 'Пожалуйста введите ваш email', trigger: 'blur' },
          { type: 'email', message: 'Проверьте корректность введенного email', trigger: 'blur' }
        ]
      },
      sending: false,
      guestSending: false,
    };
  },
  methods: {
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.authRequest();
          return true;
        }
        return false;
      });
    },
    async authRequest() {
      this.sending = true;
      this.error = '';
      this.errorPass = '';
      await this.$store.dispatch(
        'auth/AuthRequest',
        {
          email: this.model.email,
          password: this.model.pass,
        },
      ).then(() => {
        this.$router.push('/products');
        this.$store.commit('accounts/SET_EMAIL', this.model.email);
      }).catch((error) => {
        this.error = error.messageLogin;
        this.errorPass = error.messagePass;
        if (error.message) {
          this.error = error.message;
        }
      }).finally(() => {
        this.sending = false;
      });
    },
    async authRequestGuest() {
      this.guestSending = true;
      await this.$store.dispatch(
        'auth/AuthRequest',
        {
          email: this.model.email,
          password: this.model.pass,
          guest: true
        },
      ).then(() => {
        this.$store.commit('accounts/SET_EMAIL', 'testuser@email.com');
        this.$router.push('/products');
      }).catch((err) => {
        this.$message.error('Не удалось войти как гость');
        console.error('Ошибка входа как гость', err)
      }).finally(() => {
        this.guestSending = false;
      });
    }
  }
};
</script>

<style src="./auth.sass" scoped lang="sass" />
