<template>
  <div class="contact">
    <div class="contact__head">
      <h1 class="font-bg">
        Оптовая компания «Николь»
      </h1>
      <p class="font-md">
        <b>Адрес:</b> г.Омск, 22 Партсъезда, 103б
      </p>
      <p class="font-md">
        <b>Режим работы:</b> пн-пт: 09.00-17.30, сб: 09.00-15.00, вс: выходной
      </p>
      <p class="font-md">
        <b>Приемная:</b> <b>+7 (3812) 285-113,  +7 (3812)285-119,  +7 (3812) 263-545</b>, почта: office@nikol.biz
      </p>
      <p class="font-md">
        <b>Отдел сбыта:</b> <b>+7 (3812) 285-112</b>, почта: sales@nikol.biz
      </p>
      <p class="font-md">
        <b>Отдел Персонала:</b> <b>+7 (3812) 285-114</b>, почта: personal@nikol.biz
      </p>
      <p class="font-md">
        <b>Сервисный центр АКБ:</b> <b>8 (3812) 384-790</b>
      </p>
    </div>

    <p>
      <el-button
        type="primary"
        @click="showFeedback = true"
      >
        Оставить отзыв
      </el-button>
    </p>
    <hr>
    <div>
      <p class="font-bg">
        <b>Менеджер отдела сбыта – готов помочь решить вопросы:</b>
      </p>
      <div style="display: flex">
        <div style="width: 100%;margin: 0;">
          <ul class="font-md">
            <li>об условиях сотрудничества</li>
            <li>о ценах, ассортименте или характеристиках товара</li>
            <li>по работе с сайтом, или же предложения по его улучшению</li>
            <li>любые другие вопросы, касающиеся работы с ТФ «Николь»</li>
          </ul>
        </div>
        <div
          v-if="contact && contact.mosname && contact.mosphone"
          class="font-md"
          style="width: 100%;margin: 0;"
        >
          <p><b>ФИО:</b> {{ contact.mosname }}</p>
          <p><b>Номер телефона:</b> <b>{{ contact.mosphone }}</b></p>
        </div>
      </div>
    </div>
    <hr>
    <div>
      <p class="font-bg">
        <b>Менеджер торгового зала (оператор) - поможет с вопросами, предоставит информацию:</b>
      </p>
      <div
        class="font-md"
        style="display: flex"
      >
        <div style="width: 100%;margin: 0;">
          <ul>
            <li>по обработке заказа, его корректировке, добавлению или удалению выбранных позиций</li>
            <li>оформление товара под заказ или резервированию товара,</li>
            <li>решение вопроса недовоза товара,</li>
            <li>консультация при выборе ассортимента,</li>
            <li>условия и сроки доставки</li>
          </ul>
        </div>
        <div
          v-if="contact.mtzname && contact.mtzphone"
          class="font-md"
          style="width: 100%;margin: 0;"
        >
          <p><b>ФИО:</b> {{ contact.mtzname }}</p>
          <p><b>Номер телефона:</b> <b>{{ contact.mtzphone }}</b></p>
        </div>
      </div>
    </div>
    <hr>
    <div>
      <p class="font-bg">
        <b>Заместитель руководителя отдела сбыта по регионам – поможет решить вопросы на уровне руководства:</b>
      </p>
      <div
        class="font-md"
        style="display: flex"
      >
        <div style="width: 100%;margin: 0;">
          <ul style="padding-right: 20px;">
            <li>предложения или коммерческие вопросы, которые может решить только руководитель</li>
            <li>качество работы наших служб, замечания к работе сотрудников</li>
            <li>оперативным решением особенно важных и срочных вопросов</li>
          </ul>
        </div>
        <div
          v-if="contact.zrosname && contact.zrosphone"
          style="width: 100%;margin: 0;"
        >
          <p><b>ФИО:</b> {{ contact.zrosname }}</p>
          <p><b>Номер телефона:</b> <b>{{ contact.zrosphone }}</b></p>
        </div>
      </div>
    </div>
    <hr>
    <div>
      <p class="font-bg">
        <b>Вопросы и претензии по качеству обслуживания принимают сотрудники Контактного центра по номерам:</b>
      </p>
      <div
        class="font-md"
        style="display: flex"
      >
        <div style="width: 100%;margin: 0;">
          <ul>
            <li>с недовозом товаров, браком, а также проведениях ревизии</li>
            <li>жалобами на работу служб ТФ Николь, или напряжениями в работе,</li>
            <li>предоставлением информации о сроках и статусе рассмотрения Ваших обращений.</li>
          </ul>
        </div>
        <div style="width: 100%;margin: 0;">
          <p><b>Специалист контактного центра:</b></p>
          <p><b>Телефон:</b>  <b>+7 (913) 635-34-35</b></p>
          <p style="display: flex; flex-flow: nowrap">
            <b style="margin-right: 5px">Сот. тел., WhatsApp, Viber:</b>
            <b><span>+7 (913) 635-34-35<br>+7 (913) 988-06-32<br>+7 (913) 600-51-53</span></b>
          </p>
          <p>
            Телефон Контактного Центра по возвратам от покупателей: <br><b>8-913-970-12-88 Антонина<br>
              8-913-670-08-89 Наталья<br>
              8-913-150-12-80 Айжан<br>
              8-913-150-27-41 Евгения</b>.
          </p>
          <p><b>Эл.почта:</b>  kontakt@nikol.biz</p>
        </div>
      </div>
    </div>
    <el-dialog
      title="Оставить отзыв"
      class="feedback-modal"
      :visible.sync="showFeedback"
      append-to-body
      :modal="false"
    >
      <el-form
        ref="feedback"
        class="feedback-form"
        :model="feedback.formData"
        :rules="feedback.rules"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              label="Имя"
              prop="name"
            >
              <el-input
                v-model="feedback.formData.name"
                placeholder="Введите имя"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="Email"
              prop="email"
            >
              <el-input
                v-model="feedback.formData.email"
                placeholder="Укажите email"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item
              label="Отзыв"
              prop="message"
            >
              <el-input
                v-model="feedback.formData.message"
                type="textarea"
                :autosize="{ minRows: 4, maxRows: 6}"
                placeholder="Напишите текст сообщения"
              />
            </el-form-item>
          </el-col>
        </el-row>

        <el-button
          :loading="feedback.sending"
          type="primary"
          @click="submitFeedback"
        >
          Отправить
        </el-button>
      </el-form>
    </el-dialog>
    <div
      v-if="showFeedback"
      class="v-modal"
      tabindex="0"
      style="z-index: 1000;"
      @click="showFeedback = false"
    />
  </div>
</template>

<script>
import axiosInstance from 'api/axiosInstance';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'ViewContact',
  metaInfo: {
    title: 'Контакты'
  },
  data() {
    return {
      showFeedback: false,
      feedback: {
        sending: false,
        formData: {
          name: '',
          email: '',
          message: '',
        },
        rules: {
          name: [
            { required: true, message: 'Укажите имя', trigger: 'blur' },
          ],
          email: [
            { required: true, message: 'Укажите электронную почту', trigger: 'blur' },
          ],
          message: [
            { required: true, message: 'Напишите отзыв', trigger: 'blur' },
          ],
        }
      },
    };
  },
  computed: {
    ...mapState('accounts', ['name', 'email']),
    contact() {
      return this.$store.state.accounts.contact[0] || {};
    }
  },
  watch: {
    name() {
      if (this.feedback.formData.name.length || this.feedback.formData.email.length) {
        return;
      }
      this.feedback.formData.name = this.name;
      this.feedback.formData.email = this.email;
    }
  },
  mounted() {
    if (this.$route.query?.feedback !== undefined) {
      this.showFeedback = true;
    }
  },
  created() {
    this.contactRequest();
  },
  methods: {
    ...mapActions('accounts', ['GetOrg']),
    async contactRequest() {
      await this.$store.dispatch(
        'accounts/GetContact'
      ).catch((err) => {
        this.$message.error('Не удалось загрузить контакты');
        console.error('Ошибка загрузки контактов', err)
        return [];
      });
    },
    async submitFeedback() {
      const organizations = await this.GetOrg();
      const organizationName = organizations[0]?.name;
      await this.$refs.feedback.validate((valid) => {
        if (!valid) {
          return;
        }
        this.feedback.sending = true;
        let signature = '';
        if (organizationName) {
          signature += `\n\n\n---\n${organizationName}`;
        }
        const host = 'https://nikol.biz';
        axiosInstance.post(`${host}/wp-json/nikol/feedback`, {
          name: this.feedback.formData.name,
          email: this.feedback.formData.email,
          message: this.feedback.formData.message + signature,
          token: this.$store.state.auth.token
        })
          .then(async () => {
            this.showFeedback = false;
            this.$message.success('Сообщение отправлено');
            this.feedback.formData = {
              name: '',
              email: '',
              message: '',
            };
          })
          .catch((err) => {
            this.$alert(err.message || err, 'Не удалось отправить');
          }).finally(() => {
            this.feedback.sending = false;
          });
      });
    },
  }
};
</script>

<style scoped lang="sass">
  .contact
    height: calc(100vh - 42px)
    padding: 0 200px 20px
    overflow: auto
    @media (max-width: 1300px)
      padding: 0 20px
  .feedback-form
    padding: 20px
</style>
<style lang="sass">
.feedback-modal
  max-width: 600px
  padding: 20px
  margin: 0 auto
  .el-dialog
    width: 100%
</style>
