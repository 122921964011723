<template>
  <div :class="b({ admin: _roleAdmin })">
    <div :class="b('data')">
      <div
        class="font-md"
        :class="b('label')"
      >
        <strong>Дата заказа</strong>
      </div>
      <div class="block">
        <el-date-picker
          v-model="date"
          class="date-picker-history"
          type="daterange"
          size="mini"
          align="right"
          style="width: 100%"
          unlink-panels
          range-separator="|"
          start-placeholder="С"
          end-placeholder="По"
          :picker-options="pickerOptions"
          value-format="yyyy.MM.dd"
          @change="emitFind"
        />
      </div>
    </div>
    <div :class="b('status')">
      <div
        class="font-md"
        :class="b('label')"
      >
        <strong>Статус</strong>
      </div>
      <el-checkbox-group
        v-model="selectedStatuses"
        class="checkboxes"
        @change="emitFind"
      >
        <el-checkbox
          v-for="item in orderStates"
          :key="item.id"
          border
          :class="b('checkbox', { color: item.color })"
          size="small"
          :label="item.id"
          :value="item.id"
        >
          {{ item.label }}
        </el-checkbox>
      </el-checkbox-group>
    </div>
    <div
      v-if="_roleAdmin"
      :class="b('users')"
    >
      <div
        class="font-md"
        :class="b('label')"
      >
        <strong>Пользователь</strong>
      </div>
      <el-select
        v-model="user"
        class="user-input__select"
        size="small"
        style="width: 100%"
        filterable
        remote
        clearable
        placeholder="Начните вводить"
        :remote-method="findUsers"
        :loading="loadingUsers"
        @change="emitFind"
      >
        <el-option
          v-for="item in users"
          :key="item.externalId"
          :label="item.name"
          :value="item.id"
        />
      </el-select>
    </div>
    <div :class="b('search')">
      <div
        class="font-md"
        :class="b('label')"
      >
        <strong>Поиск</strong>
      </div>
      <el-input
        v-model="searchQuery"
        placeholder="Введите артикул или название товара"
        size="small"
        clearable
        style="width: 100%"
        :minlength="1"
        @clear="emitFind"
        @keydown.enter.native="emitFind"
      >
        <template #append>
          <el-button
            icon="el-icon-search"
            @click="emitFind"
          />
        </template>
      </el-input>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { orderStates } from '../utils';

const defaultSelectedStatuses = orderStates.map(orderState => orderState.id);

const pickerOptions = {
  shortcuts: [{
    text: 'Последняя неделя',
    onClick(picker) {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
      picker.$emit('pick', [start, end]);
    },
  }, {
    text: 'Последний месяц',
    onClick(picker) {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
      picker.$emit('pick', [start, end]);
    },
  }, {
    text: 'Последние 3 месяца',
    onClick(picker) {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
      picker.$emit('pick', [start, end]);
    },
  }],
};

export default {
  name: 'OrderHistorySelectors',
  block: 'filter-history',
  props: {

  },
  data() {
    return {
      pickerOptions,
      date: '',
      orderStates,
      users: [],
      orderSearchHistory: [],
      user: '',
      loadingUsers: false,
      searchQuery: '',
      selectedStatuses: defaultSelectedStatuses,
    };
  },
  computed: {
    ...mapGetters('auth', ['_roleAdmin']),
  },
  methods: {
    emitFind() {
      const {
        date, user, searchQuery
      } = this;
      this.$emit('find', {
        date, user, checkListData: this.selectedStatuses, searchQuery
      });
    },
    async findUsers(query) {
      if (query !== '') {
        this.loadingUsers = true;
        this.users = await this.$store.dispatch('admin/GetAccounts', query).catch((err) => {
          this.$message.error('Не удалось загрузить пользователей');
          console.error('Ошибка загрузки пользователей для истории заказов', err)
        });
        this.loadingUsers = false;
      } else {
        this.users = [];
      }
    },
  }
};
</script>

<style lang="sass">
.el-checkbox.filter-history__checkbox
  margin: 0 5px 0 0 !important
  @media (max-width: 700px)
    margin: 0 5px 5px 0 !important
  &_color
    $color: #3D8F5F
    color: $color
    border: 1px solid $color
    &-green.is-checked
      $color: #3D8F5F
      color: $color
      border-color: $color
      > .el-checkbox__input .el-checkbox__inner
        background-color: $color !important
        border-color: $color !important
      > .el-checkbox__label
        color: $color
    &-gray.is-checked
      $color: #7F7F7F
      color: $color
      border-color: $color
      > .el-checkbox__input .el-checkbox__inner
        background-color: $color !important
        border-color: $color !important
      > .el-checkbox__label
        color: $color
    &-red.is-checked
      $color: #ff1c0a
      color: $color
      border-color: $color
      > .el-checkbox__input .el-checkbox__inner
        background-color: $color !important
        border-color: $color !important
      > .el-checkbox__label
        color: $color
    &-orange.is-checked
      $color: #FF9B0D
      color: $color
      border-color: $color
      > .el-checkbox__input .el-checkbox__inner
        background-color: $color !important
        border-color: $color !important
      > .el-checkbox__label
        color: $color
    &-blue.is-checked
      $color: #0074F7
      color: $color
      border-color: $color
      > .el-checkbox__input .el-checkbox__inner
        background-color: $color !important
        border-color: $color !important
      > .el-checkbox__label
        color: $color
</style>

<style scoped lang="sass">
.filter-history
  display: grid
  grid-template-columns: 300px 1fr
  grid-template-areas: 'data search' 'status status'
  gap: 10px 20px
  @media (max-width: 900px)
    grid-template-columns: 1fr
    grid-template-areas: 'data' 'search' 'status'
  &_admin
    grid-template-columns: 300px 1fr
    grid-template-areas: 'data search' 'users status'
    @media (max-width: 500px)
      grid-template-columns: 1fr
      grid-template-areas: 'data' 'users' 'search' 'status'
  &__data
    grid-area: data
  &__status
    grid-area: status
    .checkboxes
      display: flex
      flex-flow: nowrap
      @media (max-width: 700px)
        flex-flow: wrap
  &__search
    grid-area: search
    flex-grow: 1
  &__users
    grid-area: users
    flex: 1
  &__label
    margin-bottom: 4px
</style>
