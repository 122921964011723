import axios from 'axios';

import router from '../router';
import store from '../store';

const sleep = m => new Promise(r => setTimeout(r, m));

const baseURL = import.meta.env.VUE_APP_BACKEND_URL || 'http://127.0.0.1:3000';

const headers = {};
// const params = {};
let hasRefreshRequest = false;


// NOTE: axiosInstance для модифицирования запросов, ответов.
const axiosInstance = axios.create({
  baseURL,
  headers,
});
// NOTE: перехвадчик для запросов
axiosInstance.interceptors.request.use(
  (request) => {
    request.headers['x-requested-by'] = 'site';

    // NOTE: добавляет аксесс токен авторизации в запрос. в будущем закомментированный код пригодится.
    const { token } = store.state.auth;
    if (token) {
      request.headers['x-access-token'] = token;
    }
    // TODO: временное решение, надо удалить, когда время жизни токена настроят
    // request.headers['x-access-token'] = store.getters['auth/token'];

    return request;
  },
  error => Promise.reject(error.response)
  ,
);
// TODO: Возможно стоит написать более качественную логику обновления запросов
// NOTE: перехватываем ответ от сервера, в дев режиме выводим в консоль.
axiosInstance.interceptors.response.use(
  (response) => {
    // store.commit('ui/LOADING', false, { root: true });

    // if (import.meta.env.DEV) {
    //   console.log(`${config.method}: ${config.url}`, data);
    // }

    return response.data;
  },
  async (error) => {
    // store.commit('ui/LOADING', false, { root: true });
    const { response } = error;

    // NOTE: Если мы получили ошибку авторизации
    if (response?.status === 401 && response.config && !response.config.__isRetryRequest && response.config.url !== '/auth/login') {
      // NOTE: сохраняем оригинальное тело запроса
      const originalRequest = error.config;

      // NOTE: если один из запросов ушёл за новой парой ждём N миллисекунд
      if (hasRefreshRequest) {
        await sleep(500);

        // NOTE: если мы всё ещё залогинены, повторяем запрос
        if (store.getters['auth/isRefreshToken']) {
          return axiosInstance(originalRequest);
        }
      }

      // NOTE: Если у нас есть рефреш-токен пробуем его обновить
      if (store.getters['auth/isRefreshToken']) {
        try {
          // NOTE: Выставляем флаг о том, что отправлен запрос свежих токенов
          hasRefreshRequest = true;
          await store.dispatch('auth/TokenRefresh');

          // NOTE: помечаем повторно отправленные запросы, чтобы потом игнорировать их в обработчике
          originalRequest.__isRetryRequest = true;
          // NOTE: повторно отправляем оригинальный запрос
          return axiosInstance(originalRequest);
        } catch (error) {
          console.log(error);
        } finally {
          hasRefreshRequest = false;
        }
      }

      // NOTE: Мы получили ошибку авторизации и не смогли обновить токен. Нужно закрыть сессию
      try {
        // NOTE: Если внутри AuthLogout закрывать сессию он будет падать с ошибкой, обернём в try ... catch
        await store.dispatch('auth/AuthLogout');
      } catch (error) {
        console.log(error);
      }
      // NOTE: Ждём выхода на страницу авторизации
      await router.push({ path: '/auth/login' });
    }

    if (!response?.status) {
      //console.error('Request failed due empty response');
    }

    if (response?.status && response.status !== 400 && response.status !== 401 && response.status !== 200 && !response?.data?.errors) {
      console.error(`Server respond with strange status: ${response.status}`);
      return Promise.reject(response?.data?.message || 'Произошла ошибка, попробуйте выполнить заново или обновить страницу');
    }

    const networkErrorBody = {
      message: 'Не удалось выполнить запрос, попробуйте ещё раз или обновите страницу'
    };

    // NOTE: Пробрасываем дальше сообщение с телом ошибки
    return Promise.reject(response?.data || networkErrorBody);
  },

);

export default axiosInstance;
