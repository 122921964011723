<template>
  <n-table
    ref="nikolTable"
    v-model="activeRowId"
    class="product-table"
    :data="productsData"
    :default-sort="{ prop: sortField, order: sortOrder }"
    :table-loading="productListLoading"
    :expected-length="10"
    :loading-more="loadingMore"
    :row-class="rowClassName"
    row-index-key="id"
    :columns="[
      {
        name: 'Артикул',
        prop: 'article',
        width: windowSize.width < 700 ? '70px' : '115px',
        sortable: true,
        show: showColumns.hide,
        translucent: tableVisual.focus
      },
      {
        name: 'Бренд',
        prop: 'brand',
        width: '130px',
        sortable: true,
        show: showColumns.hide2,
        translucent: tableVisual.focus
      },
      { name: 'Код 1С', prop: 'externalId', width: '90px', show: showColumns.hide3, translucent: tableVisual.focus },
      { name: 'ОЕМ', prop: 'OEM', width: '115px', show: showColumns.hide5, translucent: tableVisual.focus },
      { name: 'Название', prop: 'name', width: 'minmax(100px, 1fr)', sortable: true },
      { name: '', prop: 'info', width: '110px', align: 'center', show: windowSize.width <= 620 },
      {
        name: 'К',
        prop: 'minShippingRate',
        width: '40px',
        align: 'center',
        show: showColumns.hide4,
        translucent: tableVisual.focus
      },
      { name: 'Цена', prop: 'price', width: windowSize.width < 700 ? '80px' : '90px', align: 'right', sortable: true },
      {
        name: 'Остаток Омск',
        prop: 'stockbalance',
        width: '86px',
        align: 'right',
        translucent: tableVisual.focus,
        show: _role !== 'guest'
      },
      { name: 'Купить', prop: 'bay', width: '122px', align: 'center' },
    ]"
    :main-columns="mainColumns"
    @sort-change="productsSort"
    @scroll-end="loadMore"
    @hover="onHover"
  >
    <template #emptyText>
      <ElEmpty>
        <template #default>
          <el-button
            v-if="currentCategory"
            type="primary"
            size="small"
            @click="resetCategory"
          >
            Искать во всех категориях
          </el-button>
          <el-button
            v-if="search && !currentCategory"
            size="small"
            type="primary"
            @click="$router.push('/find?query=' + search)"
          >
            Искать во всех каталогах
          </el-button>
        </template>
        <template #description>
          <p><strong>Товары не найдены</strong></p>
          Попробуйте изменить настройки фильтров
          <template v-if="search">
            или поисковой запрос
          </template>
        </template>
      </ElEmpty>
    </template>
    <template #nameHead>
      <span class="v-step-3">Название</span>
    </template>
    <template #minShippingRateHead>
      <el-tooltip
        v-once
        effect="dark"
        content="Минимальная кратность"
        placement="top"
      >
        <div>K.</div>
      </el-tooltip>
    </template>
    <template #stockbalanceHead>
      Остаток
    </template>
    <template
      v-once
      #article="{ row }"
    >
      <el-tooltip
        v-if="row.article.length >= (windowSize.width < 700 ? 5 : 14)"
        effect="dark"
        :content="row.article"
        placement="top"
      >
        <div class="product-table__article">
          {{ row.article }}
        </div>
      </el-tooltip>
      <template v-else>
        {{ row.article }}
      </template>
    </template>
    <template
      v-once
      #brand="{ row }"
    >
      <el-tooltip
        v-if="row.brand.length >= 14"
        effect="dark"
        :content="row.brand"
        placement="top"
      >
        <div class="product-table__brand">
          {{ row.brand }}
        </div>
      </el-tooltip>
      <template v-else>
        {{ row.brand }}
      </template>
    </template>
    <template
      v-once
      #OEM="{ row }"
    >
      <el-tooltip
        v-if="row.OEM.length >= 14"
        effect="dark"
        :content="row.OEM"
        placement="top"
      >
        <div class="product-table__oem">
          {{ row.OEM }}
        </div>
      </el-tooltip>
      <template v-else>
        {{ row.OEM }}
      </template>
    </template>
    <template #name="{ row }">
      <div class="product-table__name">
        <span class="product-table__main">
          <el-tooltip
            v-if="!!row.isProductGroup"
            effect="dark"
            placement="top"
            content="Акционный товар"
          >
            <span class="percent">%</span>
          </el-tooltip>
          <el-image
            v-if="displayCatalogImages"
            :src="`${urlSrc + row.externalId}.JPEG`"
            :preview-src-list="[`${urlSrc + row.externalId}.JPEG`]"
            class="product-table__image"
            fit="cover"
            lazy
          >
            <template #error>
              <span class="image-slot" />
            </template>
          </el-image>
          {{ row.name }}
        </span>

        <div
          v-if="windowSize.width > 620"
          class="button-group"
          :style="{
            marginRight: (
              row.baskets !== null && typeof row.baskets === 'object' ? Object.entries(row.baskets).length * 35 : 0
            ) + 'px'
          }"
        >
          <button
            v-if="row.stockbalance + row.regionalstockbalance === 0"
            class="button-group__button"
            @click.prevent.stop="searchAllCatalogs(row)"
          >
            <el-tooltip
              effect="dark"
              content="Проверить все склады (сроки доставки у товаров будут дольше)"
            >
              <i class="el-icon-search" />
            </el-tooltip>
          </button>
          <button
            class="button-group__button"
            @click.prevent.stop="showProductInfo(row)"
          >
            <span
              v-if="row.id && productInfoModalLoadingIndex === row.id"
              class="el-icon-loading"
            />

            <el-tooltip
              effect="dark"
              content="Инфо"
            >
              <i class="el-icon-info" />
            </el-tooltip>
          </button>
          <button
            class="button-group__button"
            @click.prevent.stop="SET_ANALOGS_FOR_ID(row.id)"
          >
            <el-tooltip
              effect="dark"
              content="Аналоги"
            >
              <i class="el-icon-refresh" />
            </el-tooltip>
          </button>
          <button
            class="button-group__button"
            @click.prevent.stop="addWishList(row)"
          >
            <el-tooltip
              effect="dark"
              content="В ожидание"
            >
              <i class="el-icon-alarm-clock" />
            </el-tooltip>
          </button>
          <button
            class="button-group__button"
            @click.prevent.stop="showDeliveryInfo(row)"
          >
            <el-tooltip
              effect="dark"
              content="Дата доставки"
            >
              <i class="el-icon-truck" />
            </el-tooltip>
          </button>
        </div>

        <basket-indicator
          v-if="row.baskets"
          :baskets="row.baskets"
          class="product-table__name__baskets"
        />
      </div>
    </template>
    <template
      v-once
      #info="{ row }"
    >
      <div style="display: flex; flex-flow: nowrap">
        <button
          class="button-group__button"
          @click.prevent.stop="showProductInfo(row)"
        >
          <span
            v-if="row.id && productInfoModalLoadingIndex === row.id"
            class="el-icon-loading"
          />
          <el-tooltip
            effect="dark"
            content="Подробнее о товаре"
          >
            <i class="el-icon-info" />
          </el-tooltip>
        </button>
        <button
          class="button-group__button"
          @click.prevent.stop="SET_ANALOGS_FOR_ID(row.id)"
        >
          <el-tooltip
            effect="dark"
            content="Найти аналоги"
          >
            <i class="el-icon-refresh" />
          </el-tooltip>
        </button>
        <button
          class="button-group__button"
          @click.prevent.stop="addWishList(row)"
        >
          <el-tooltip
            effect="dark"
            content="Добавить в лист ожидания"
          >
            <i class="el-icon-alarm-clock" />
          </el-tooltip>
        </button>
        <button
          class="button-group__button"
          @click.prevent.stop="showDeliveryInfo(row)"
        >
          <el-tooltip
            effect="dark"
            content="Узнать дату доставки"
          >
            <i class="el-icon-truck" />
          </el-tooltip>
        </button>
      </div>
    </template>
    <template #price="{ row }">
      <priceOutput :price="normalizePriceMarkup(getPrice(row))" />
    </template>
    <template #regionalstockbalance="{ row }">
      {{ row.regionalstockbalance || '0' }}
    </template>
    <template
      v-once
      #bay="{ row, index }"
    >
      <add-to-cart
        v-if="row.stockbalance + row.regionalstockbalance !== 0 || returnBasketId !== null"
        v-model="row.quantity"
        :class="{ 'v-step-35': index === 2 }"
        :loading="loadingIndex === row.id"
        :disabled="row.stockbalance + row.regionalstockbalance === 0 && returnBasketId === null"
        :min="+row.minShippingRate || 1"
        :max="row.stockbalance + row.regionalstockbalance - ((row.stockbalance + row.regionalstockbalance) % +row.minShippingRate)"
        :step="+row.minShippingRate"
        @addToCart="addProduct(row)"
      />
    </template>
  </n-table>
</template>

<script>
import declOfNum from 'utils/declOfNum';
import { getDeliveryDate, getDeliveryHint } from 'utils/deliveryDays';
import { formatDateNumeric } from 'utils/formatDate';
import { getPrice,normalizePriceMarkup } from 'utils/normalizePrice';
import {
  mapActions, mapGetters, mapMutations, mapState
} from 'vuex';

import AddToCart from '@/components/add-to-cart/addToCart';
import basketIndicator from '@/components/product-table/layout/basketIndicator';
import genProductInfo from '@/components/product-table/utils/genProductInfo';
import hide from '@/components/tables/mixins/hide';

export default {
  name: 'NikolProductsTables',
  components: {
    AddToCart,
    basketIndicator,
  },
  mixins: [hide],
  props: {
    loadingIndex: {
      type: Number,
      default: null,
      require: false,
    },
  },
  data() {
    return {
      loadingMore: false,
      urlSrc: import.meta.env.VUE_APP_IMG_URL,
      activeRowId: null,
    };
  },
  computed: {
    ...mapState('products', {
      productsCount: 'productsCount',
      productsData: 'products',
      productListLoading: 'productListLoading',
      convertInTenge: 'convertInTenge',
      sortField: 'sortField',
      sortOrder: 'sortOrder',
      productInfoModalLoadingIndex: 'productInfoModalLoadingIndex',
      deliveryDays: 'deliveryDays',
      regionalDeliveryDays: 'regionalDeliveryDays',
    }),
    ...mapState('products', ['search', 'outOfStock',]),
    ...mapState('ui', ['tableVisual', 'userCurrency', 'windowSize', 'displayCatalogImages']),
    ...mapState('categories', ['currentCategory']),
    ...mapState('accounts', ['city']),
    ...mapState('returns', {
      returnBasketId: state => state.basketId,
    }),
    ...mapGetters('auth', ['_warehouseId', '_role']),
    mainColumns() {
      if (this.windowSize.width > 1200) return [];
      if (this.windowSize.width > 1000) return ['article', 'brand', 'name', 'info', 'price', 'stockbalance', 'regionalstockbalance', 'bay'];
      if (this.windowSize.width > 800) return ['article', 'brand', 'name', 'info', 'price', 'bay'];
      if (this.windowSize.width > 620) return ['article', 'name', 'info', 'price', 'bay'];
      return ['article', 'name', 'price'];
    },
    loadedAll() {
      return this.productsData.length === this.productsCount;
    }
  },
  watch: {
    $route(to, from) {
      if (from.params?.category !== to.params?.category) {
        this.getCategoryID(parseInt(to.params?.category || 0, 10));
      } else if (
        from.query?.price !== to.query?.price
        || from.query?.brand !== to.query?.brand
        || from.query?.outOfStock !== to.query?.outOfStock
        || from.query?.promo !== to.query?.promo
        || from.query?.onlyGroups !== to.query?.onlyGroups
      ) {
        // Else because getCategoryID applies filters
        this.filtersUpdated();
      }
    }
  },
  mounted() {
    const categoryId = this.$route.params.category ? parseInt(this.$route.params.category, 10) : null;
    this.SET_CURRENT_CATEGORY(categoryId);
    this.SET_CATEGORY_ID(categoryId);
    this.GetFilters().catch((err) => {
      this.$message.error('Не удалось загрузить фильтры');
      console.error('Ошибка загрузки фильтров', err);
    });
    this.getProducts();
  },
  methods: {
    declOfNum,
    ...mapMutations(
      'products',
      [
        'SET_ANALOGS_FOR_ID', 'SET_PRODUCT_INFO_MODAL_DATA', 'SET_PRODUCT_INFO_MODAL_LOADING_INDEX',
        'SET_PRODUCT_SORT', 'SET_CATEGORY_ID'
      ]
    ),
    ...mapMutations('categories', ['SET_CURRENT_CATEGORY']),
    ...mapActions('products', ['GetProductsList', 'GetProductInfo', 'GetFilters']),
    ...mapActions('wishlist', ['addToWishList']),
    ...mapActions('basket', ['checkDeliveryDate']),
    ...mapActions('events', ['logHover']),
    async getProducts(append = false) {
      await this.GetProductsList(append)
        .catch((error) => {
          this.$message.error('Не удалось загрузить товары');
          console.error('Ошибка загрузки товаров', error)
        });
    },
    addProduct(product) {
      this.$emit('addProduct', product);
    },
    async loadMore() {
      if (this.productListLoading || this.loadingMore || this.loadedAll || !this.productsCount) return;
      this.loadingMore = true;
      await this.getProducts(true);
      this.loadingMore = false;
    },
    async getCategoryID(categoryId) {
      this.$emit('categoryChanged', categoryId);
      this.SET_CURRENT_CATEGORY(categoryId);
      this.SET_CATEGORY_ID(categoryId);
      this.GetFilters().catch((err) => {
        this.$message.error('Не удалось загрузить фильтры');
        console.error('Ошибка загрузки фильтров', err);
      });
      await this.getProducts();
    },
    async filtersUpdated() {
      await this.getProducts();
    },
    /**
     * Устанавливает сортировку продуктов
     *
     * @param data {{}}
     * @param data.prop {string}
     * @param data.order {'ASC' | 'DESC'}
     * @return {Promise<void>}
     */
    async productsSort({ prop, order }) {
      if (!['ASC', 'DESC'].includes(order)) {
        order = 'ASC'
      }
      this.SET_PRODUCT_SORT({
        sortField: prop,
        sortOrder: order,
      })
      await this.getProducts()
    },
    showProductInfo(row) {
      this.SET_PRODUCT_INFO_MODAL_LOADING_INDEX(row.id);
      this.GetProductInfo(row.id)
        .then((res) => {
          this.SET_PRODUCT_INFO_MODAL_DATA(genProductInfo(res, row));
        })
        .catch((err) => { this.$alert(err.message || err, 'Не удалось получить данные о товаре'); })
        .finally(() => { this.SET_PRODUCT_INFO_MODAL_LOADING_INDEX(null); });
    },
    async addWishList(row) {
      this.addToWishList({
        productId: row.id,
        quantity: row.quantity,
        basketName: 'Из каталога',
      }).then(() => { this.$message.success('Товар добавлен в лист ожидания'); })
        .catch((err) => { this.$alert(err.message || err, 'Не удалось добавить товар в лист ожидания'); });
    },
    async showDeliveryInfo(row) {
      if (!this.city?.cityId) {
        this.$alert(
          'Не указан регион доставки, свяжитесь с менеджером',
          'Дата доставки неизвестна',
        ).catch(() => {
          //
        });
        return;
      }
      await this.checkDeliveryDate();
      const isRegionalWarehouse = row.warehouseId && row.warehouseId !== this._warehouseId;
      const deliveryDays = getDeliveryDate({
        isRegionalWarehouse,
        deliveryDays: row.supplier ? JSON.parse(row.supplier).stocks.deliveryDays : 0
      });
      this.$alert(
        getDeliveryHint({ isRegionalWarehouse }),
        `Дата доставки товара ${deliveryDays ? formatDateNumeric(deliveryDays.deliveryDate) : 'неизвестна'}`,
      ).catch(() => {
        //
      });
    },
    rowClassName(row, index) {
      return {
        empty: row.stockbalance + row.regionalstockbalance === 0,
        even: this.tableVisual.striped && index % 2 === 0,
        bigger: this.displayCatalogImages && this.windowSize.width > 700,
      };
    },
    onHover(product) {
      this.logHover(product.id)
    },
    normalizePriceMarkup,
    getPrice,
    async resetCategory() {
      this.SET_CATEGORY_ID(null);
      this.SET_CURRENT_CATEGORY(null);
      this.$router.push('/products');
      // await this.getProducts();
    },
    searchAllCatalogs(row) {
      this.$store.commit('products/SET_SEARCH_TEXT', row.name);
      this.$router.push('/find?query=' + row.name)
    }
  }
};
</script>

<style lang="sass">
.product-table
  .button-group
    visibility: hidden
    flex-flow: nowrap
    margin-left: 10px
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.5)
    opacity: 0
    transition: opacity .1s
    z-index: 1
    display: inline-flex
    border-radius: 3px
    position: absolute
    right: 0
    &__button
      font-size: 12px
      display: flex
      flex-flow: nowrap
      line-height: 1
      white-space: nowrap
      cursor: pointer
      background: #fff
      border: 1px solid #DCDFE6
      margin-left: -1px
      color: #606266
      padding: 3px 8px
      transition: 0.2s
      &:first-child
        border-top-left-radius: 3px
        border-bottom-left-radius: 3px
        margin-left: 0
      &:last-child
        border-top-right-radius: 3px
        border-bottom-right-radius: 3px
      &:hover, &:active, &:focus
        color: #1f75a8
        border-color: #1f75a8
        background: #def0ff
        z-index: 1
      span
        margin-right: 5px
  .n-table-row:hover .button-group,
  .n-table-row_active .button-group
    transition: opacity .1s
    visibility: visible
    opacity: 1
  .n-table-row__col
    &_oem, &_brand, &_article
      color: #676767
</style>

<style lang="sass">
.product-table
  .empty > *:not(:last-child)
    color: #fb7e7e
  .even.n-table-row > *
    background: #fafafa
  .bigger.n-table-row td
    font-size: 14px
  .percent
    color: rgb(24, 180, 34)
    font-weight: bold
    margin-right: 5px
  &__oem, &__brand, &__article
    width: 100%
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
  &__name
    display: flex
    flex-flow: nowrap
    align-items: center
    width: 100%
    position: relative
    @media (max-width: 600px)
      font-size: 12px
    &__baskets
      margin-left: auto
  &__main
    display: flex
    align-items: center
    gap: 10px
    @media (max-width: 800px)
      align-items: flex-start
      flex-flow: column
  &__image
    width: 70px
    height: 70px
    flex-shrink: 0
  .price-output
    font-size: 13px
</style>
