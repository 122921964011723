import Vue from 'vue';

import axiosInstance from '../../api/axiosInstance';

/**
 * @typedef productInMultiBasket
 * @property {number} productId
 * @property {number} quantity
 * @property {number} price
 * @property {number} tengePrice
 * @property {json | string | null} supplier
 * @property {string} name
 * @property {string} article
 * @property {string} brand
 * @property {number} minShippingRate
 * @property {number} basketItemId
 */

/**
 * @typedef basketItem
 * @property {number} id
 * @property {number | null} productId
 * @property {number} basketId
 * @property {number} quantity
 * @property {string} name
 * @property {string} article
 * @property {number | null} minShippingRate
 * @property {any} active
 * @property {number} brand
 * @property {number} price
 * @property {number | null} stockBalanceRegionalWarehouse
 * @property {number} stockBalance
 * @property {number | null} warehouseId
 * @property {JSON} supplier
 * @property {number} tengePrice
 */


/**
 * @typedef multiBasket
 * @property {number} id
 * @property {string} name
 * @property {string} colour
 * @property {number} basketSum
 * @property {number} tengeBasketSum
 * @property {productInMultiBasket[]} basketItems
 */

/**
 * @typedef changeQuantityItemResponse
 * @property {number} basketId
 * @property {string} createdAt
 * @property {number} id
 * @property {number} productId
 * @property {number} productPrice
 * @property {number} quantity
 */

const state = () => ({
  /** @type multiBasket[] */
  multiBasket: [],
  /** @type basketItem[] */
  basket: [],
  basketsLoading: false,
  basketLoading: false,
  stockbalance: {
    productsWithoutActive: [],
    productsWithoutPrice: [],
    productsWithoutStockBalance: [],
  },
  /** @type { number | undefined } */
  activeBasketId: Number(localStorage.getItem('activeBasketId')) || undefined,
  priceUpdate: {
    /** @type {{oldPrice: Number, newPrice: Number, productName: String}[]} */
    prices: [],
    visible: false,
    error: ''
  },
  deliveryDays: null,
  regionalDeliveryDays: null,
});

const mutations = {
  ADD_PRODUCTS_IN_BASKET(state, products) {
    state.basket = products;
  },
  SET_BASKETS_LOADING(state, basketsLoading) {
    state.basketsLoading = basketsLoading;
  },
  SET_BASKET_LOADING(state, basketLoading) {
    state.basketLoading = basketLoading;
  },
  SET_ACTIVE_BASKET(state, basketId) {
    state.activeBasketId = basketId;
    localStorage.setItem('activeBasketId', basketId);
  },
  SET_MULTI_BASKET(state, multiBasket) {
    state.multiBasket = multiBasket;
  },
  ADD_PRODUCTS_IN_MULTI_BASKET(state, {
    basketId,
    products
  }) {
    const basketIndex = state.multiBasket.findIndex(basket => basket.id === basketId);
    if (basketIndex < 0) {
      return;
    }

    state.multiBasket[basketIndex].basketItems = products;
  },
  ADD_BASKET_IN_MULTI_BASKET(state, basket) {
    state.multiBasket.push(basket);
  },
  RENAME_BASKET(state, { basketId, name }) {
    state.multiBasket.forEach((item) => {
      if (item.id === basketId) {
        item.name = name;
      }
    });
  },
  SET_ITEM_QUANTITY(state, { id, quantity, max }) {
    const itemIndex = state.basket.findIndex(item => item.id === id);
    if (itemIndex < 0) return;
    Vue.set(state.basket, itemIndex, {
      ...state.basket[itemIndex],
      quantity,
      max: max || state.basket[itemIndex].max
    });
  },
  SET_PRODUCT_BASKET_ITEM_ID(state, { productId, basketItemId }) {
    const itemIndex = state.basket.findIndex(item => item.productId === productId);
    if (itemIndex < 0) return;
    Vue.set(state.basket, itemIndex, {
      ...state.basket[itemIndex],
      id: basketItemId,
    });
  },
  SET_MULTI_BASKET_ITEM_QUANTITY(state, { id, quantity, max }) {
    const [basketIndex, itemIndex] = state.multiBasket.reduce((acc, basket, index) => {
      if (!basket.basketItems) return acc;
      const i = basket.basketItems.findIndex(item => item.basketItemId === id);
      return i >= 0 ? [index, i] : acc;
    }, [-1, -1]);
    if (basketIndex < 0 || itemIndex < 0) return;
    Vue.set(state.multiBasket[basketIndex].basketItems, itemIndex, {
      ...state.multiBasket[basketIndex].basketItems[itemIndex],
      quantity,
      max: max || state.multiBasket[basketIndex].basketItems[itemIndex].max
    });
    const sumPrices = (acc, item) => ({
      basketSum: acc.basketSum + item.price * item.quantity,
      tengeBasketSum: acc.tengeBasketSum + item.tengePrice * item.quantity
    });
    Vue.set(state.multiBasket, basketIndex, {
      ...state.multiBasket[basketIndex],
      ...state.multiBasket[basketIndex].basketItems.reduce(sumPrices, { basketSum: 0, tengeBasketSum: 0 })
    });
  },
  SET_MULTI_BASKET_ITEM_STOCK_BALANCE(state, { id, stockBalance }) {
    const [basketIndex, itemIndex] = state.multiBasket.reduce((acc, basket, index) => {
      if (!basket.basketItems) return acc;
      const i = basket.basketItems.findIndex(item => item.basketItemId === id);
      return i >= 0 ? [index, i] : acc;
    }, [-1, -1]);
    if (basketIndex < 0 || itemIndex < 0) return;
    Vue.set(state.multiBasket[basketIndex].basketItems, itemIndex, {
      ...state.multiBasket[basketIndex].basketItems[itemIndex],
      stockBalance: stockBalance || state.multiBasket[basketIndex].basketItems[itemIndex].stockBalance
    });
    const sumPrices = (acc, item) => ({
      basketSum: acc.basketSum + item.price * item.quantity,
      tengeBasketSum: acc.tengeBasketSum + item.tengePrice * item.quantity
    });
    Vue.set(state.multiBasket, basketIndex, {
      ...state.multiBasket[basketIndex],
      ...state.multiBasket[basketIndex].basketItems.reduce(sumPrices, { basketSum: 0, tengeBasketSum: 0 })
    });
  },
  SET_ITEM_PRICE(state, { id, price }) {
    state.basket = state.basket.map((item) => {
      if (item.id === id) return {
        ...item,
        price,
      };
      return item;
    });
  },
  SET_STOCKBALANCE(state, stockbalance) {
    state.stockbalance = stockbalance;
  },
  /**
   * Дополняет цены в списке обновлённых цен
   * @param state
   * @param {{oldPrice: Number, newPrice: Number, productName: String}[]} priceUpdate
   */
  SET_PRICE_UPDATE(state, priceUpdate) {
    /* Если нам уже пришло изменение цены на товар, с тем же названием, меняем там только newPrice */
    priceUpdate.forEach((item) => {
      const index = state.priceUpdate.prices.findIndex(price => price.productName === item.productName);
      if (index > -1) state.priceUpdate.prices[index].newPrice = item.newPrice;
      else state.priceUpdate.prices.push(item);
    });
    state.priceUpdate.visible = state.priceUpdate.prices.length > 0;
    state.priceUpdate.error = '';
  },
  SET_PRICE_UPDATE_ERROR(state, err) {
    state.priceUpdate.prices = [];
    state.priceUpdate.visible = true;
    state.priceUpdate.error = err;
  },
  RESET_PRICE_UPDATE(state) {
    state.priceUpdate = {
      prices: [],
      visible: false,
      error: ''
    };
  },
  /**
   * Удаляет товар из корзины в multiBasket
   * @param state
   * @param data
   * @param {number} data.basketId - Id корзины
   * @param {number} data.basketItemId - Id товара в корзине
   */
  DELETE_PRODUCT_IN_MULTI_BASKET(state, { basketId, basketItemId }) {
    const basketIndex = state.multiBasket.findIndex(item => item.id === basketId);
    if (basketIndex === -1) return;
    const isDelItem = item => item.basketItemId === basketItemId;
    Vue.set(state.multiBasket, basketIndex, {
      ...state.multiBasket[basketIndex],
      /* Удаляем товар */
      basketItems: state.multiBasket[basketIndex].basketItems.filter(item => !isDelItem(item)),
      /* Пересчитываем суммы после удаления товара */
      ...state.multiBasket[basketIndex].basketItems.reduce(
        (accum, item) => ({
          basketSum: accum.basketSum + isDelItem(item) ? 0 : item.price,
          basketTengeSum: accum.basketTengeSum + isDelItem(item) ? 0 : item.tengePrice
        }),
        { basketSum: 0, basketTengeSum: 0 } // Сделал аккумулятор объектом, чтобы не бегать по массиву 2 раза
      )
    });
    const sumPrices = (acc, item) => ({
      basketSum: acc.basketSum + item.price * item.quantity,
      tengeBasketSum: acc.tengeBasketSum + item.tengePrice * item.quantity
    });
    Vue.set(state.multiBasket, basketIndex, {
      ...state.multiBasket[basketIndex],
      ...state.multiBasket[basketIndex].basketItems.reduce(sumPrices, { basketSum: 0, tengeBasketSum: 0 })
    });
  },
  SET_DELIVERY_DAYS(state, deliveryDays) {
    state.deliveryDays = deliveryDays;
  },
  SET_REGIONAL_DELIVERY_DAYS(state, regionalDeliveryDays) {
    state.regionalDeliveryDays = regionalDeliveryDays;
  },
};

const actions = {
  async AddProductInBasket({
    state, getters, rootGetters, dispatch, commit
  }, product) {
    return new Promise((resolve, reject) => {
      const { id, quantity, supplier, fromRecommendation } = product;
      if (quantity === 0) {
        reject(new Error('Вы не можете добавить 0 товара'));
        return;
      }
      if (rootGetters['auth/_role'] === 'guest') {
        reject(new Error('Вы в режиме гостя. Авторизуйтесь для добавления товара в корзину'));
        return;
      }
      const basketThisProduct = getters.activeBasketData.basketItems.find(prod => prod.productId === id);
      const setProductInBasket = () => {
        commit('products/SET_PRODUCT_BASKET_QUANTITY', {
          id,
          basketId: state.activeBasketId,
          quantity: !basketThisProduct ? quantity : basketThisProduct.quantity + quantity
        }, { root: true });
      };

      if (!basketThisProduct) {
        dispatch('PostProductInBasket', {
          id,
          quantity,
          supplier,
          fromRecommendation,
        })
          .then(() => {
            setProductInBasket();
            resolve();
          })
          .catch(e => reject(e));
      } else {
        dispatch('PutChangeQuantityProduct', {
          basketItemId: basketThisProduct.id,
          quantity: basketThisProduct.quantity + quantity,
          supplier,
        })
          .then(() => {
            setProductInBasket();
            resolve();
          })
          .catch(e => reject(e));
      }
    });
  },
  async PostProductInBasket({ state, dispatch, rootGetters }, { id, quantity, supplier, fromRecommendation = false }) {
    const basketId = state.activeBasketId;

    if (typeof supplier === 'string') {
      supplier = JSON.parse(supplier);
    }

    return new Promise((resolve, reject) => {
      /*
       * Если у пользователя не прогрузились корзины,
       * но прогрузились товары basketId будет undefined.
       *
       * Грузим корзины и выкидываем ошибку
       */
      if (!basketId) {
        dispatch('GetMultiBasket');
        reject(new Error('Произошла проблема с корзинами, попробуйте ещё раз'));
      }
      if (rootGetters['auth/_role'] === 'guest') {
        reject(new Error('Вы в режиме гостя. Авторизуйтесь для добавления товара в корзину'));
        return;
      }
      dispatch('checkDeliveryDate');
      const data = supplier
        ? { quantity, supplier, basketId, fromRecommendation }
        : { basketId, productId: id, quantity, fromRecommendation };
      axiosInstance({
        url: '/api/basketItems/add',
        method: 'post',
        data
      })
        .then(async () => {
          await dispatch('GetProductsBasket');
          await dispatch('recommendations/getRecommendations', null, {root: true});
          resolve();
        })
        .catch(err => reject(err));
    });
  },
  DeleteProductInBasket({ dispatch }, { basketItemId }) {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: '/api/basketItems/delete',
        method: 'delete',
        data: {
          basketItemId
        }
      })
        .then(async () => {
          await dispatch('GetProductsBasket');
          await dispatch('recommendations/getRecommendations', null, {root: true});
          resolve();
        })
        .catch((err) => {
          dispatch('GetProductsBasket');
          if (err.message === 'Данный товар не найден в корзине, возможно, он был удален ранее') {
            resolve();
            return;
          }
          reject(new Error(err.message || err));
        });
    });
  },
  /**
   * GetMultiBasket получает корзины
   * @param _ { any } - context
   * @return {Promise<productInMultiBasket[]>}
   */
  GetMultiBasket({ commit }) {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: '/api/baskets/withItems',
      })
        .then((/** @type {productInMultiBasket[]} */ multibasket) => {
          commit('SET_MULTI_BASKET', multibasket);
          resolve(multibasket);
        })
        .catch(err => reject(err));
    });
  },
  GetProductsBasket({ state, commit }) {
    return new Promise((resolve, reject) => {
      if (!state.activeBasketId) {
        resolve();
        return;
      }
      commit('SET_BASKET_LOADING', true);
      axiosInstance({
        url: '/api/basketItems',
        params: {
          basketId: state.activeBasketId
        }
      })
        .then((data) => {
          // Fix backend different key names >:(
          if (data.basketItems) {
            data.basketItems.map(basketItem => basketItem.basketItemId = basketItem.id);
          }
          commit('ADD_PRODUCTS_IN_BASKET', data.basketItems);
          commit('ADD_PRODUCTS_IN_MULTI_BASKET', {
            basketId: state.activeBasketId,
            products: data.basketItems
          });
          commit('SET_DELIVERY_DAYS', data.deliveryDays);
          commit('SET_REGIONAL_DELIVERY_DAYS', data.regionalDeliveryDays);
          resolve(data);
        })
        .catch(err => {
          commit('ADD_PRODUCTS_IN_BASKET', []);
          reject(new Error(err.message || err))
        })
        .finally(() => commit('SET_BASKET_LOADING', false));
    });
  },
  /**
   * Добавляет новую корзину
   *
   * @param context
   * @param {string} name - Название корзины
   * @return {Promise<unknown>}
   */
  CreateBasket({ dispatch, commit }, name) {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: '/api/baskets/createBasket',
        method: 'post',
        data: {
          name
        }
      })
        .then(async (basket) => {
          await dispatch('GetMultiBasket');
          commit('SET_ACTIVE_BASKET', basket.basketId);
          dispatch('GetProductsBasket');

          resolve();
        })
        .catch((err) => {
          reject(err.message);
        });
    });
  },
  XlsUploadBasket({ dispatch, commit }, { products, basketId }) {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: '/api/baskets/upload',
        method: 'post',
        data: {
          products,
          basketId: basketId ?? undefined
        }
      })
        .then(async (/** @type {{id: number, basketId: number}} */ basket) => {
          await dispatch('GetMultiBasket');
          commit('SET_ACTIVE_BASKET', basketId ? basket.id : basket.basketId);
          dispatch('GetProductsBasket');

          resolve();
        })
        .catch(({ message }) => {
          reject(message);
        });
    });
  },
  DeleteBasket({ dispatch }, basketId) {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: '/api/baskets/deleteBasket',
        method: 'delete',
        data: {
          basketId
        }
      })
        .then(async () => {
          await dispatch('GetMultiBasket');
          await dispatch('recommendations/getRecommendations', null, {root: true});
          resolve();
        })
        .catch(err => reject(new Error(err.message || err)));
    });
  },
  RenameBasketData({ commit }, { basketId, name }) {
    commit('RENAME_BASKET', {
      basketId,
      name
    });
  },
  PutRenameBasket({ dispatch }, { basketId, name }) {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: '/api/baskets/renameBasket',
        method: 'put',
        data: {
          basketId,
          name
        }
      })
        .then(() => {
          dispatch('RenameBasketData', {
            basketId,
            name
          });
        })
        .catch(err => reject(err));
    });
  },
  PutChangeQuantityProduct({ state, commit, dispatch }, {
    basketItemId, quantity, supplier, updateBasket = true
  }) {
    return new Promise((resolve, reject) => {
      axiosInstance.put('/api/basketItems/changeQuantity', {
        basketItemId,
        quantity,
        supplier
      })
        .then(/** @param {changeQuantityItemResponse[]} resp */ async (resp) => {
          if (resp.length > 2) { // Выпилить эту хрень
            if (state.activeBasketId) await dispatch('GetProductsBasket');
            if (state.multiBasket && state.multiBasket.length) await dispatch('GetBasketsWithItems');
            resolve();
            return;
          }
          commit('SET_ITEM_QUANTITY', { id: basketItemId, quantity });
          // ID элемента корзины может измениться, если выборка будет с другого склада, обрабатываем этот кейс
          if (resp.length) {
            resp.forEach((changedBasketItem) => {
              commit('SET_PRODUCT_BASKET_ITEM_ID', { productId: changedBasketItem.productId, basketItemId: changedBasketItem.id });
            });
          }
          commit('SET_MULTI_BASKET_ITEM_QUANTITY', { id: basketItemId, quantity });
          if (updateBasket) {
            await dispatch('GetProductsBasket', {
              basketId: `${state.activeBasketId}`
            });
          }
          resolve();
        })
        .catch((err) => {
          if (err.message) {
            const availableQuantity = parseInt(err.message.trim().split(' ').slice(-1)[0], 10);
            const params = {
              id: basketItemId,
              quantity: availableQuantity || 1,
              max: availableQuantity || Infinity
            };
            commit('SET_ITEM_QUANTITY', params);
            commit('SET_MULTI_BASKET_ITEM_QUANTITY', params);
          }
          reject(err);
        });
    });
  },
  async ClearBasket({ dispatch }, { basketId }) {
    await axiosInstance({
      url: '/api/baskets/clear',
      method: 'post',
      params: {
        basketId
      }
    })
      .then(() => {
        dispatch('GetProductsBasket');
      })
      .catch(err => console.log(err));
  },
  DeleteProductInMultiBasket({ commit }, { basketId, basketItemId }) {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: '/api/basketItems/delete',
        method: 'delete',
        data: {
          basketItemId,
        },
      })
        .then(() => {
          commit('DELETE_PRODUCT_IN_MULTI_BASKET', { basketId, basketItemId });
          resolve();
        })
        .catch(err => reject(new Error(err.message || err)));
    });
  },
  async GetBasketsWithItems({ commit, dispatch }) {
    commit('SET_BASKETS_LOADING', true);
    return axiosInstance({
      url: '/api/baskets/withItems'
    })
      .then((data) => {
        commit('SET_MULTI_BASKET', data);
        dispatch('renderBasketIndicators');
        //dispatch('setProductsFromAllBaskets');
        return data;
      })
      .finally(() => {
        commit('SET_BASKETS_LOADING', false);
      });
  },
  async renderBasketIndicators({ commit, state }) {
    state.multiBasket.forEach((basket) => {
      basket.basketItems.forEach((product) => {
        commit('products/SET_PRODUCT_BASKET_QUANTITY', {
          id: product.productId,
          basketId: basket.id,
          quantity: product.quantity
        }, { root: true });
      });
    });
  },
  // TODO: Why this not working?
  setProductsFromAllBaskets({ commit, getters }) {
    const basketItems = getters.activeBasketData.basketItems;
    basketItems.map(basketItem => basketItem.id = basketItem.basketItemId);
    commit('ADD_PRODUCTS_IN_BASKET', basketItems);
  },
  async CheckStockbalance({ commit }) {
    await axiosInstance({
      url: '/api/baskets/checkStockbalance'
    })
      .then((data) => {
        commit('SET_STOCKBALANCE', data);
      })
      .catch(err => console.log(err));
  },
  async StockbalanceFromBaskets(_, basketIds) {
    return axiosInstance({
      url: '/api/wishList/fromBaskets',
      method: 'POST',
      data: {
        basketIds
      }
    });
  },
  async priceUpdate({ commit, dispatch }) {
    dispatch('GetProductsBasket');
    await axiosInstance('/api/users/priceUpdated')
      .then((/** @type {{oldPrice: Number, newPrice: Number, productName: String}[]} */ resp) => {
        commit('SET_PRICE_UPDATE', resp);
      })
      .catch(({ message }) => {
        commit('SET_PRICE_UPDATE_ERROR', message
          ? `Стоимость товаров в корзине изменена, но возникла ошибка: ${message}`
          : 'Стоимость товаров в корзине изменена');
      });
  },
  async checkDeliveryDate({ state }, basketId = null) {
    return axiosInstance({
      url: '/api/basketItems/checkDeliveryDate',
      params: {
        basketId: basketId || state.activeBasketId
      }
    });
  },
  async moveItemToBasket({ dispatch }, { basketItemId, newBasketId }) {
    return axiosInstance({
      url: '/api/basketItems/changeBasket',
      method: 'PUT',
      data: {
        basketItemId,
        newBasketId
      }
    }).then(async () => {
      await dispatch('GetProductsBasket');
      await dispatch('GetMultiBasket');
    });
  },
};

const getters = {
  activeBasketData: state => state.multiBasket.find(basket => basket.id === state.activeBasketId),
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
