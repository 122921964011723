<template>
  <div
    ref="wishlist"
    :class="b()"
  >
    <page-header>
      Лист ожидания
      <template #subtitle>
        Здесь собраны товары, которые вы не смогли заказать ранее
      </template>
    </page-header>
    <template v-if="loading || wishList.length">
      <n-table
        :data="wishList"
        :loading="loading"
        :table-loading="loading && !wishList.length"
        :expected-length="3"
        :default-sort="{ prop: sortField, order: sortOrder }"
        :row-class="rowClassName"
        :columns="[
          { name: '', prop: 'checkbox', width: '30px', align: 'center' },
          { name: 'Артикул', prop: 'article', width: '115px', translucent: true, },
          { name: 'Бренд', prop: 'brand', width: '130px', translucent: true, },
          { prop: 'name', name: 'Название', width: 'minmax(130px, 1fr)', sortable: true },
          { prop: 'quantity', name: 'Кол-во', width: '93px', align: 'center', translucent: true },
          { prop: 'stockBalance', name: 'Остаток', width: '60px', align: 'center', translucent: true },
          {
            prop: 'expecteddateshipping',
            name: 'Планируемая дата поступления',
            width: '120px',
            align: 'center',
            translucent: true
          },
          {
            prop: 'notification',
            name: 'Сообщать о наличии',
            width: '80px',
            align: 'center',
            translucent: true
          },
          { prop: 'createdAt',
            name: 'Добавлено',
            width: '110px',
            sortable: true,
            translucent: true
          },
          {
            prop: 'basketName',
            name: 'Корзина',
            width: '110px',
            translucent: true
          },
          { name: '', prop: 'delete', width: '65px', align: 'center' },
        ]"
        :main-columns="mainColumns"
        @sort-change="sort($event)"
      >
        <template #checkboxHead>
          <el-checkbox
            :indeterminate="selected.length !== wishList.length && selected.length > 0"
            :value="selected.length === wishList.length"
            @change="bulkSelection"
          />
        </template>
        <template #checkbox="{ row }">
          <el-checkbox
            v-if="row.stockBalance"
            :value="selected.includes(row.id)"
            @change="$event ? selected.push(row.id) : selected = selected.filter(item => item !== row.id)"
          />
        </template>
        <template #createdAt="{ row }">
          {{ formatDateTime(row.createdAt) }}
        </template>
        <template #article="{ row }">
          <el-tooltip
            v-if="row.article.length >= 14"
            effect="dark"
            :content="row.article"
            placement="top"
          >
            <div class="product-table__article">
              {{ row.article }}
            </div>
          </el-tooltip>
          <template v-else>
            {{ row.article }}
          </template>
        </template>
        <template #name="{ row }">
          {{ row.name }}
          <el-tooltip
            v-if="row.productId"
            effect="dark"
            content="Аналоги"
          >
            <el-button
              size="mini"
              style="margin-left: 10px"
              icon="el-icon-refresh"
              class="analogs-button"
              @click="showAnalogs(row.productId)"
            />
          </el-tooltip>
        </template>
        <template #quantity="scope">
          <n-input-number
            v-model="scope.row.quantity"
            :min="calcStep(scope.row)"
            :max="calcMax(scope.row)"
            :step="calcStep(scope.row)"
            @change="changeQuantity(scope.row)"
          />
        </template>
        <template #notification="{ row }">
          <el-checkbox
            :checked="row.notification"
            @change="changeProductNotification(row.id, $event)"
          />
        </template>
        <template #expecteddateshipping="{ row }">
          {{ !isNaN(new Date(row.expecteddateshipping)) ? formatDate(row.expecteddateshipping) : row.expecteddateshipping }}
        </template>
        <template #delete="{ row }">
          <el-button
            type="danger"
            icon="el-icon-delete"
            size="mini"
            @click="deleteItem(row)"
          />
        </template>
        <template
          v-if="windowSize.width <= 700"
          #after="{ row }"
        >
          <div
            v-if="row.expand"
            class="n-table-row__col n-table-row__col_minor"
          >
            <div class="minor-info-block">
              <div class="minor-info-block__title">
                Артикул
              </div>
              <div>{{ row.article }}</div>
            </div>
            <div class="minor-info-block">
              <div class="minor-info-block__title">
                Бренд
              </div>
              <div>{{ row.brand }}</div>
            </div>
            <div class="minor-info-block">
              <div class="minor-info-block__title">
                Добавлен
              </div>
              <div>{{ formatDateTime(row.createdAt) }}</div>
            </div>
            <div class="minor-info-block">
              <div class="minor-info-block__title">
                Планируемая дата поступления
              </div>
              <div>{{ formatDate(row.expecteddateshipping) }}</div>
            </div>
            <div class="minor-info-block">
              <div class="minor-info-block__title">
                Корзина
              </div>
              <div>{{ row.basketName }}</div>
            </div>
            <div class="minor-info-block">
              <el-checkbox
                :checked="row.notification"
                label="Сообщать о наличии"
                size="mini"
                @change="changeProductNotification(row.id, $event)"
              />
            </div>
          </div>
        </template>
      </n-table>
      <el-row
        class="footer"
        :gutter="10"
        type="flex"
        justify="space-between"
      >
        <el-col
          :span="50"
          :class="b('baskets')"
        >
          <el-select
            v-if="baskets"
            v-model="selectedCheckout"
            clearable
            placeholder="Корзины"
            class="footer__button"
            size="small"
          >
            <el-option
              v-for="item in baskets"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
          <el-button
            class="ml-10"
            width="400px"
            size="small"
            :type="selectedCheckout === null || !selected.length ? 'info' : 'primary'"
            :disabled="selectedCheckout === null || !selected.length"
            @click="wishListToBasket"
          >
            {{ selectedCheckout === 0 ? 'Создать новую корзину' : 'Добавить в корзину' }}
          </el-button>
        </el-col>
        <el-col
          v-if="!isModalEnabled"
          :span="50"
        >
          <el-button
            :class="b('notification-button')"
            size="small"
            plain
            round
            @click="enableModal"
          >
            Включить окно обновлений
          </el-button>
        </el-col>
      </el-row>
    </template>
    <div ref="endChecker" />
    <el-empty
      v-if="!loading && !wishList.length"
      description="У вас нет товаров в листе ожидания"
    />
  </div>
</template>

<script>
import axiosInstance from 'api/axiosInstance';
import { mapActions, mapMutations, mapState } from 'vuex';

import PageHeader from '@/components/page-header/page-header';

export default {
  name: 'WishList',
  block: 'wishlist',
  components: { PageHeader },
  metaInfo: {
    title: 'Лист ожидания'
  },
  data: () => ({
    wishList: [],
    /** @type {string} */
    sortField: 'createdAt',
    /** @type { 'ASC' | 'DESC' } */
    sortOrder: 'DESC',
    loading: true,
    loadedAll: false,
    selectedCheckout: null,
    isModalEnabled: true,
    selected: []
  }),
  computed: {
    ...mapState('basket', ['multiBasket', 'activeBasketId']),
    ...mapState('ui', ['tableVisual', 'windowSize']),
    baskets() {
      return [
        { id: 0, name: 'Создать новую корзину' },
        ...this.multiBasket
      ];
    },
    mainColumns() {
      if (this.windowSize.width <= 700) return [
        'checkbox',
        'name',
        'quantity',
        'stockBalance',
        'delete',
      ];
      return [
        'checkbox',
        'article',
        'brand',
        'name',
        'quantity',
        'stockBalance',
        'expecteddateshipping',
        'notification',
        'createdAt',
        'basketName',
        'delete',
      ];
    }
  },
  async mounted() {
    this.getWishList({}, false);
    if (!this.multiBasket.length) {
      this.GetMultiBasket().catch((err) => {
        this.$message.error('Не удалось загрузить список корзин');
        console.error('Ошибка загрузки корзин для вишлиста', err)
        return [];
      });
    }
    this.$refs.wishlist.addEventListener('scroll', this.infiniteHandler, { passive: true });
    this.isModalEnabled = localStorage.getItem('doNotShowWishlistModal') === null;
  },
  beforeDestroy() {
    this.$refs.wishlist.removeEventListener('scroll', this.infiniteHandler);
  },
  methods: {
    ...mapActions('basket', ['GetProductsBasket', 'GetMultiBasket']),
    ...mapActions('wishlist', ['changeProductNotificationState', 'changeProductQuantity']),
    ...mapMutations('basket', ['SET_ACTIVE_BASKET']),
    ...mapActions('products', ['GetAnalogs']),
    getWishList({
      max = 50,
      offset = 0,
      sortField = this.sortField,
      sortOrder = this.sortOrder
    }, concat = true) {
      this.loading = true;
      axiosInstance({
        url: '/api/wishList',
        params: {
          max,
          offset,
          sortField,
          sortOrder
        }
      })
        .then((data) => {
          this.loadedAll = data.length < 50;
          this.wishList = concat ? this.wishList.concat(data) : data;
        })
        .catch((err) => {
          this.$message.error('Не удалось загрузить лист ожидания');
          console.error('Ошибка загрузки вишлиста', err)
          return [];
        }).finally(() => {
          this.loading = false;
        });
    },
    async infiniteHandler() {
      if (this.loadedAll) this.$refs.wishlist.removeEventListener('scroll', this.infiniteHandler);
      if (!this.$refs.endChecker || this.loadedAll || !this.wishList.length || this.loading) return;
      if (window.innerHeight >= this.$refs.endChecker.getBoundingClientRect().bottom - 400) {
        this.getWishList({ offset: this.wishList.length });
      }
    },
    async sort(sort) {
      this.sortField = sort.prop;
      this.sortOrder = sort.order;
      this.getWishList({ max: this.wishList.length, offset: 0 }, false);
    },
    bulkSelection() {
      this.selected = this.selected.length !== this.wishList.length
        ? this.wishList.filter(item => !!item.stockBalance).map(item => item.id)
        : [];
    },
    expandRow(row) {
      const find = item => item === row;
      const val = row.expand !== undefined ? !row.expand : true;
      const index = this.wishList.findIndex(find);
      if (index > -1) {
        this.$set(this.wishList[index], 'expand', val);
      }
    },
    async wishListToBasket() {
      axiosInstance.post('/api/wishList/toBasket', {
        wishListIds: this.selected,
        basketId: this.selectedCheckout || 0
      })
        .then(async () => {
          this.$message.success('Товары добавлены в корзину');
          this.selected = [];
          this.getWishList({ offset: 0 }, false);
        })
        .catch((err) => {
          this.$alert(err.message || err, 'Не удалось добавить товары в корзины');
        });
    },
    async changeProductNotification(productId, enabled) {
      await this.changeProductNotificationState({ productId, enabled })
        .then(async () => {
          this.$message.success(`Уведомление ${enabled ? 'включено' : 'выключено'}`);
        })
        .catch((err) => {
          this.$alert(
            err.message || err,
            `Не удалось ${enabled ? 'включить' : 'выключить'} уведомление о поступлении`
          );
        });
    },
    changeQuantity(row) {
      this.changeProductQuantity({
        productId: row.id,
        quantity: row.quantity
      });
    },
    formatDate: strDate => new Date(strDate).toLocaleString('ru', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    }),
    formatDateTime: strDate => new Date(strDate).toLocaleString('ru', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit'
    }),
    async deleteItem(row) {
      await axiosInstance.delete(`/api/wishList/${row.id}`)
        .then(() => { this.wishList = this.wishList.filter(item => item.id !== row.id); })
        .catch((err) => { this.$alert(err.message || err, 'Не удалить товар'); });
    },
    rowClassName(row, index) {
      return {
        empty: !row.stockbalance,
        even: this.tableVisual.striped && index % 2 === 0,
      };
    },
    enableModal() {
      localStorage.removeItem('doNotShowWishlistModal');
      this.$message.success('Окно обновлений листа ожидания включено');
      this.isModalEnabled = true;
    },
    calcStep: row => +row.minShippingRate || 1,
    calcMax(row) {
      let count = 9999;
      if (row.supplier && typeof row.supplier === 'string') count = JSON.parse(row.supplier).stocks.count;
      if (row.supplier && typeof row.supplier === 'object') count = row.supplier.stocks.count;
      if (row.warehouseId === 1) count = row.stockBalance;
      if (row.warehouseId === 2) count = row.stockBalanceRegionalWarehouse;
      return count - (count % this.calcStep(row));
    },
    showAnalogs(id) {
      this.GetAnalogs(id);
    },
  }
};
</script>

<style lang="sass">
  .n-table-row__col_basketName
    word-break: break-all
  .n-table-row:hover .analogs-button
    opacity: 1
    visibility: visible
</style>
<style scoped lang="sass">
.wishlist
  padding: 20px 200px
  overflow: auto
  max-height: calc(100vh - 42px)
  .empty > *:not(:last-child)
    color: #fb7e7e
  .even.n-table-row > *
    background: #fafafa
  @media (max-width: 1500px)
    padding: 20px
  &__notification-button
    @media (max-width: 720px)
      margin-top: 20px
  &__baskets
    display: flex

.footer
  margin-top: 20px
  @media (max-width: 720px)
    flex-direction: column
.analogs-button
  opacity: 0
  visibility: hidden
  transition: all .3s
</style>
